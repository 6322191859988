import React, { useState } from "react";
import { getImage } from "gatsby-plugin-image";
import { useI18n } from "@lib/i18n/i18n-react";
import ActivityCard from "@components/activity-card";
import "./index.scss";

function FilterSelect({ name, label, onCategorySelected, categories, defaultCategory = null }) {
  const onFilterChanged = ({ target: { value } }) => onCategorySelected(value);
  return (
    <div className="FilterSelect" onChange={onFilterChanged} role="radiogroup" aria-label={label}>
      {Object.entries(categories).map(([categoryKey, categoryTitle]) =>
        <label key={categoryKey} htmlFor={`FilterSelect__${categoryKey}`}>
          <input type="radio" id={`FilterSelect__${categoryKey}`} name={name} value={categoryKey} defaultChecked={categoryKey === defaultCategory} />
          <span>{categoryTitle}</span>
        </label>
      )}
    </div>
  );
}

export default function ActivitiesList({ activities }) {
  const { t } = useI18n();

  const CATEGORY_ALL = t("activities.categoryAllKey");
  const activityCategories = new Set(activities.flatMap(activity =>
    activity.frontmatter.categories || []
  ));
  const filterCategories = Object.fromEntries(
    Object.entries(t("activities.filterCategories"))
      .filter(([key, activityName]) => activityCategories.has(key) || key === CATEGORY_ALL)
  );

  const [currentCategory, setFilterCategory] = useState(CATEGORY_ALL);

  const filteredActivities = currentCategory === CATEGORY_ALL
    ? activities
    : activities.filter(({ frontmatter }) =>
      frontmatter.categories && frontmatter.categories.some(category => category === currentCategory)
    );

  return (
    <section className="ActivitiesSection">
      <FilterSelect
        name="category"
        label={t("activities.categoryFilterLabel")}
        onCategorySelected={setFilterCategory}
        categories={filterCategories}
        defaultCategory={CATEGORY_ALL}
      />

      <div className="ActivitiesList" key={currentCategory}>
        {filteredActivities.map(({ id, frontmatter: { title, cardImage }, fields: { url } }) => {
          return (
            <ActivityCard
              key={id}
              name={title}
              image={getImage(cardImage)}
              url={url} />
          );
        })}
      </div>
    </section>
  );
}