import React from "react";
import { Link } from "@lib/i18n/localized-routing";
import { GatsbyImage } from "gatsby-plugin-image";
import "./index.scss";

export default function ActivityCard({ name, image, url }) {
    return (
        <Link className="ActivityCard" to={url}>
            <GatsbyImage className="ActivityCard__image" image={image} alt={name} />
            <div className="ActivityCard__heading-wrapper">
                <h2 className="ActivityCard__heading">
                    {name}
                </h2>
            </div>
        </Link>
    );
}