import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/_layout";
import HeroHeader from "@components/hero-header";
import ActivitiesList from "@components/activities-list";
import "./index.scss";

export default function HomeLayout({
  data: {
    pageData: { frontmatter: { description, title: pageTitle, heroAlt, heroImage }, body },
    activitiesMdx: { edges: activities },
  },
  pageContext
}) {

  return (
    <Layout title={pageTitle} description={description} image={heroImage} {...pageContext}>

      <main id="main" className="ActivitiesPage">

        <HeroHeader
          heroHeading={pageTitle}
          heroText={body}
          heroImage={heroImage}
          heroImageAlt={heroAlt}
        />

        <ActivitiesList activities={activities.map(a => a.node)} />

      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Activities($language: String!, $id: String!) {
    pageData: mdx(id: {eq: $id}) {
      frontmatter {
        title
        heroAlt
        description
        heroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
    activitiesMdx: allMdx(
      filter: {fields: {collection: {eq: "activities"}, language: {eq: $language}}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            categories
            cardImage {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
            order
          }
          fields {
            url
          }
        }
      }
    }
  }
`;