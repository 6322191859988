import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MarkdownContent from "@components/markdown/markdown-content";
import "./index.scss";

export default function HeroHeader({ heroText, heroImage, heroImageAlt }) {
    return (
      <header className="HeroHeader">
        <div className="HeroHeader__container">
          <div className="HeroHeader__content">
            <MarkdownContent>{heroText}</MarkdownContent>
          </div>
          <div className="HeroHeader__image-wrapper">
            <GatsbyImage className="HeroHeader__image" image={getImage(heroImage)} alt={heroImageAlt} />
          </div>
        </div>
      </header>
    );
  }